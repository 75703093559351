<script setup>
import { inject, ref } from 'vue';
import ToolsChoser from './ToolsChoser.vue';
import TextGen from './tools/TextGen.vue';
import ReviewsLinker from './tools/ReviewsLinker.vue';
import CatDefiner from './tools/CatDefiner.vue';

const mode = inject('mode', ref('1'))

</script>

<template>
  <div class="toolsprovider w100">
    <div v-if="!mode">
      <h1 class="toolsprovider__title">Выберите, что хотите сделать:</h1>
      <ToolsChoser :v-model="mode" @update:modelValue="mode = $event" :look="2" />
    </div>
    <TextGen v-if="mode == 'textgen'"/>
    <ReviewsLinker v-if="mode == 'reviewslink'"/>
    <CatDefiner v-if="mode == 'catdef'"/>
  </div>
</template>
<style lang="sass">
.toolsprovider
  --formwidth: 500px
  .wfrom
    width: var(--formwidth)
  &__big
    display: flex
    gap: 30px

  &__title
    font-weight: 400
    font-size: 25px
    margin: 0 0 10px
  &__form
    width: var(--formwidth)
    max-width: 100%
    display: flex
    flex-direction: column
    gap: 10px
  &__table
    margin: 20px 0
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px
    border-collapse: collapse
    th
      font-size: 16px
    td, th
      padding: 5px 15px
      border: 1px solid rgba(0, 0, 0, .2)
    td
      font-size: 14px
    tr:nth-child(odd) td
      background: rgba(0,0,0,.01)
    &-red
      background: rgb(255, 222, 222)
  .bg-pink
    background: rgb(255, 222, 222)
  &__text
    margin: 0 0 10px
    &_mini
      font-size: 10px
    &_sm
      font-size: 12px
  &__btn
    display: flex
    svg
      width: 50px
      margin: -20px 0px
  &__stickdown
    background: white
    position: sticky !important
    bottom: 0px
    width: 100%
    margin-top: 20px
    padding-bottom: 10px
    margin: 20px -30px 0px
    padding: 20px 30px 10px
    width: calc(100% + 60px)
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px
  &__label
    display: flex
    gap: 20px
    align-items: center
    font-size: 14px
  &__label2
    display: flex
    flex-direction: column
    gap: 5px
    align-items: flex-start
    &-content
      display: flex
      width: 100%
      gap: 10px
      >*
        flex-grow: 1
  &__label &__input
    width: auto
    flex-grow: 1
  &__input    
    padding: 5px
    border: 1px solid rgba(0,0,0,.2)
    min-height: 40px
    display: block
    width: 100%
    max-width: 100%
    border-radius: 2px
    &_select
      font-size: 18px
  & &__input
    &_area
      &:focus, &:focus-visible
        border: 1px solid rgba(0,0,0,.3)
        outline: none
      height: 200px
      resize: vertical
</style>