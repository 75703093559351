import { ref } from "vue"
import { useStore } from "vuex"

export default function useApi() {
  const store = useStore()
  const isWorking = ref(false)
  async function getCatlists(options = {}) {
    isWorking.value = true
    const tries = options?.tries || 1
    const timeout = options?.timeout || 1000 
    for (let i = 1; i <= tries; i++) {
      let response = await fetch(store.state.url + 'catlists', {
        method: 'GET',
        credentials: 'include',
      }).then(async res => {
        if (res.ok) 
          return res.json()
        else
          throw (await res.text())
      }).catch(err => { alert(err); console.error(err); return false })
      if (response || (i == tries)) {
        isWorking.value = false
        return response
      }
      else {
        await new Promise(resolve => setTimeout(resolve, timeout))
      }
    }

  }

  async function setCatlist(name, textlist, options = {}) {
    isWorking.value = true
    const tries = options?.tries || 1
    const timeout = options?.timeout || 1000 
    for (let i = 1; i <= tries; i++) {
      let response = await fetch(store.state.url + 'catlist', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, cats: textlist })
      }).then(async res => {
        if (res.ok) 
          return true
        else
          throw (await res.text())
      }).catch(err => { alert(err); console.error(err); return false })
      if (response || (i == tries)) {
        isWorking.value = false
        return response
      }
      else {
        await new Promise(resolve => setTimeout(resolve, timeout))
      }
    }
  }

  async function deleteCatlist(name,  options = {}) {
    isWorking.value = true
    const tries = options?.tries || 1
    const timeout = options?.timeout || 1000 
    for (let i = 1; i <= tries; i++) {
      let response = await fetch(store.state.url + 'catlist', {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name })
      }).then(async res => {
        if (res.ok) 
          return true
        else
          throw (await res.text())
      }).catch(err => { alert(err); console.error(err); return false })
      if (response || (i == tries)) {
        isWorking.value = false
        return response
      }
      else {
        await new Promise(resolve => setTimeout(resolve, timeout))
      }
    }
  }

  return { getCatlists, setCatlist, deleteCatlist, isWorking }
}