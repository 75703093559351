<script setup>
import { inject, ref } from 'vue';
//eslint-disable-next-line
const { name } = defineProps(['name'])
//eslint-disable-next-line
const viewBoxes = inject('viewBoxes', ref({}))
</script>
<template>
  <svg :viewBox="viewBoxes[name]">
    <use :xlink:href="`#${name}`"></use>
  </svg>
</template>