<script setup>
import useApi from '@/composables/useApi';
import useFiles from '@/composables/useFiles';
import Btn from '../buttons/Btn.vue';
import { ref, useTemplateRef } from 'vue';
import useGrouping from '@/composables/useGrouping';
const { askAi, cost, costReadable, isWorking } = useApi()
const { readFile, downloadTextAsFile } = useFiles()

const coursesInput = useTemplateRef('coursesInput')
const reviewsInput = useTemplateRef('reviewsInput')

const gptVersion = ref('gpt-4o-mini')
const reviews = ref([])
const courses = ref([])
const results = ref([])

const statfound = ref(0)
const statsearched = ref(0)


const { grouping, isGroupCorrect, groupingInput } = useGrouping()

async function setFileContent(e) {
  const content = await readFile(e)

  let res = []
  if (e.target === coursesInput.value) {
    courses.value = []
    if (!content) return
    let v = content.split('\n').map(el => el.trim()).filter(el => el)
    for (let course of v) {
      const id = course.match(/^\d+\|/)?.[0]
      if (!id) {
        console.error('Нет ID у курса ' + course)
        coursesInput.value.value = ''
        return alert('Нет ID у курса ' + course)
      }
      res.push({ id: id.replace('|', ''), text: course.replace(id, '') })
    }
    if (Array.isArray(res))
      courses.value = res
    else {
      alert('Неверный тип массива')
      console.error('Неверный тип массива')
      console.error(res)
    }
  }
  if (e.target === reviewsInput.value) {
    reviews.value = []
    if (!content) return
    let v = content.split('\n').map(el => el.trim()).filter(el => el)
    for (let review of v) {
      const id = review.match(/^\d+\|/)?.[0]
      if (!id) {
        console.error('Нет ID у отзыва ' + review)
        reviewsInput.value.value = ''
        return alert('Нет ID у отзыва ' + review)
      }
      res.push({ id: id.replace('|', ''), text: review.replace(id, '') })
    }
    reviews.value = res
  }
}


async function gen() {
  isWorking.value = true
  cost.value = statfound.value = statsearched.value = 0
  results.value = []
  let data = []
  let cur = 0
  
  while(cur < reviews.value.length) {
    let { store, isERROR } = await processReviews(grouping.value, reviews.value.slice(cur, reviews.value.length))
    for (let el of store) {
      statsearched.value++
      if (el.result)
        statfound.value++
    }

    cur += store.length
    data.push(...store)
    if (isERROR !== false) {
      alert(`Ошибка при обработке отзыва ${isERROR}, дальшная работа прервана`) 
      break
    }
  }

  for (let el of data) {
    let res = el.result
    if (res) 
      results.value.push(el.rid + ' | ' + res?.id+'|'+ res?.text)
    else if (res === 0)
      results.value.push(`${el.rid} | Не определено `)
    else
      results.value.push(`${el.rid} | ОШИБКА Некорректный ответ от ИИ `)
  }

  isWorking.value = false
  if (results.value.length > 0)
    downloadTextAsFile(`reviews_linker_results_${new Date().toLocaleDateString('ru-RU')}_.txt`, results.value.join('\n'))
}

async function processReviews(max, reviews) {
  if (!courses?.value?.map) {
    console.error(courses.value)
    console.error(typeof (courses.value))
    console.error(Array.isArray(courses.value))
    return console.error('хз')
  }
  const courselist = courses.value.map((el) => el.text.trim()).join('\n')
  let courselist_multiple = courses.value.map((el,idx) => (idx + 1) + '. ' + el.text.trim()).join('\n')

  let store = [], toask = 0
  let multiple = (max > 1) && (reviews.length > 1)
  let isERROR = false
  for (let review of reviews) {
    let add = multiple ? ` #${toask + 1}` : ''
    //const courselist = courses.value.map((el, index) => `${index + 1}.${el.text}`).join('\n')
    //const prompt = `Вот текст отзыва:\n${review.text?.trim()}\n\nТвоя задача: \n1. Определи название курса у этого отзыва.\n Выбери подходящий курс из следующего списка: \n${courselist}\n\n3. Твой ответ должен содержать исключительно одно название курса из списка. Нежелательно, но если в отзыве не указано название курса или указанного курса в списке нет - дай в ответ только цифру 0.`;
    let initprompt = `Вот текст отзыва${add}:\n${review.text?.trim()}\n\nОпредели название или направление (что изучают или какую профессию осваивают на курсе) курса у этого отзыва. Если информации о названии или хотя направлении курса нет, ответь 0.`
    let candidates = courses.value.map(el => el.text.trim().toLowerCase().replace(/\s+/g, ' ')).filter(el => review.text.toLowerCase().replace(/\s+/g, ' ').includes(el))
    if (candidates.length > 0) {
      initprompt += ` Может, речь про курс ${candidates.join(' или ')}?`
    }

    let res1 = await askAi(initprompt, gptVersion.value, { tries: 3, addcost: true })
    if (typeof (res1?.answer) != 'string') {
      let v = review.text.split('\n')[0].trim()
      if (v.length > 50) {
        v = v.substr(0, 50)
        v += '...'
      }
      isERROR = v
      break
    }
    res1.answer = res1.answer.trim()
    let found = !['0', '0.'].includes(res1.answer)
    if (found) {
      toask++
    }
    store.push({ rid: review.id, initprompt, answer: res1.answer, result: found || 0 })
    if (toask >= max) break
  }
  
  if (toask === 0)
    return { store, isERROR }

  let prompt, solo = (!multiple || toask == 1)
  if (solo)
    prompt = `Теперь выбери НАИБОЛЕЕ подходящий курс из следующего списка: \n${courselist}\n\n. Твой ответ должен содержать исключительно одно название курса из списка. Если ни одного подходящего курса в списке нет - дай в ответ только цифру 0`;
  else {
    prompt = `Теперь для каждого отзыва выбери НАИБОЛЕЕ подходящий курс из следующего списка: \n${courselist_multiple}\n\n. Твой ответ должен состоять из ${toask} строк, каждая строка N - номер курса из списка для отзыва #N. Если ни одного подходящего курса для отзыва N в списке нет - просто напиши 0 в его строку.`;
  }
  let idxes = store.map((el, i) => ({ ...el, index: i })).filter(el => el.result !== 0).map(el => el.index)
  const history = store.filter(el => el.result !== 0)
    .map(el => [
      { role: 'user', content: el.initprompt },
      { role: 'assistant', content: el.answer },
    ]).flat()

  //const prompt = `Вот список курсов, запомни их, это важно: \n${courselist}\n\nВот текст отзыва:\n${review.text?.trim()}\n\nТвоя задача: \n1. Определи название курса у этого отзыва. \n2. Выбери подходящий курс из предоставленного ранее списка. Твой ответ должен содержать исключительно одно название курса из списка. Крайне нежелательно, но если в отзыве не указано название курса или указанного курса в списке нет - дай в ответ только цифру 0.`;
  //console.log(review.text?.trim().substr(0, 100))
  let res = await askAi(prompt, gptVersion.value, { tries: 3, addcost: true, history })
  let resAr = res.answer.trim().split('\n').map(el => el.trim().toLowerCase())
  if (resAr.length != idxes.length) {
    for (let idx of idxes) {
      store[idx].result = false
    }
    return { store, isERROR }
  }
  
  for (let i = 0; i < idxes.length; i++) {
    let ans = resAr[i]
    let nodotans = ans
    if (nodotans.match(/\.$/))
      nodotans = nodotans.replace(/\.$/, '')
    let nnans = ans.replace(/^\s*[#№]?\s*\d+\s*\.?\s*/,''), nnnodotans = nodotans.replace(/^\s*[#№]?\s*\d+\s*\.?\s*/,'')
    
    if (ans) {
      if ([nodotans, nnnodotans].includes('0'))
        store[idxes[i]].result =  0
      else {
        if (solo)
          store[idxes[i]].result = courses.value.find(el => [ans, nodotans,nnans,nnnodotans].includes(el.text.toLowerCase())) || console.log(ans) || null
        else {
          let v = -1
          for (let el of [nnans, nnnodotans, ans, nodotans]) {
            if (!isNaN(parseInt(el))) {
              v = parseInt(el)
              v--
              break
            }
          }        
          
          store[idxes[i]].result = courses.value[v] || console.log(ans) || null
        }
      }
    } else
      store[idxes[i]].result =  false
  }
  return { store, isERROR }
}

</script>
<template>
  <h1 class="toolsprovider__title">Привязать отзывы к курсам</h1>
  <form @submit.prevent="gen" class="toolsprovider__form">
    <label class="toolsprovider__label finput-label">
      <span>Txt файл с курсами</span>
      <input name="courses" ref="coursesInput" class="finput" type="file" @change="setFileContent" />
    </label>
    <label class="toolsprovider__label finput-label">
      <span>Txt файл с отзывами</span>
      <input name="reviews" ref="reviewsInput" class="finput" type="file" @change="setFileContent" />
    </label>
    <label class="toolsprovider__label">
      <span>Группировать:</span>
      <input type="number" ref="groupingInput" class="toolsprovider__input" v-model="grouping"/>
    </label>
    <select class="toolsprovider__input toolsprovider__input_select" v-model="gptVersion">
      <option value="gpt-4o-mini">GPT-4o mini</option>
      <option value="gpt-4o">GPT-4o</option>
    </select>

    <p v-if="statsearched" class="toolsprovider__text">Найдено {{ statfound }} из {{ statsearched }} ({{ (statfound /
      statsearched * 100).toFixed(2) }}%)</p>
    <p v-if="cost" class="toolsprovider__text">Примерная стоимость: {{ costReadable }}</p>
    <Btn color="dark" :disabled="isWorking || !isGroupCorrect" class="toolsprovider__btn">
      <template v-if="isWorking">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="20" stroke-width="8" stroke="#007bff"
            stroke-dasharray="31.41592653589793 31.41592653589793" fill="none" stroke-linecap="round">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s"
              keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
          </circle>
        </svg>
        <span>Привязываю...</span>
      </template>
      <span v-else>Привязать</span>
    </Btn>
  </form>
</template>
<style lang></style>