import { createStore } from 'vuex'

export default createStore({
  state: {
    url: '',
    mode: 'text',
    loads: {},
    allusers: [],
    dialogs: [],
    current_id: null,
    model: 'GPT-4-mini',
    messages: [],
    instream: false,
    contextEnabled: false,
    Role: null
  },
  mutations: {
    setMode(state, mode) {
      state.mode = mode
    },
    setDialogId(state, id) {
      state.current_id = id
    },
    setDialogs(state, value) {
      state.dialogs = value
    },
    setModel(state, model) {
      state.model = model
    },
    setContextEnabled(state, v) {
      if (state.instream)
        return alert('Нельзя это сделать во время стриминга сообщения')
      state.contextEnabled = v
    },
    setAllUsers(state, users) {
      if (state.instream)
        return alert('Нельзя это сделать во время стриминга сообщения')
      state.allusers = users
    },
    setMessages(state, messages) {
      if (state.instream)
        return alert('Нельзя это сделать во время стриминга сообщения')
      state.messages = messages
    },
    addMessage(state, message) {
      state.messages = [...state.messages, message]
    },
    setLastMessage(state, message) {
      state.messages[state.messages.length - 1] = message
    },
    setUrl(state, url) {
      if (state.instream)
        return alert('Нельзя это сделать во время стриминга сообщения')
      state.url = url
    },
    toggleLoad(state, name) {
      if (state.loads[name])
        delete state.loads[name]
      else
        state.loads[name] = true
    },
    setRole(state, Role) {
      if (state.instream)
        return alert('Нельзя это сделать во время стриминга сообщения')
      state.Role = Role
    },
    setStream(state, value) {
      state.instream = value
    },
  },
  actions: {
    async getUsers({ state, commit }) {
      commit('toggleLoad', 'getUsers')
      console.log('here')
      let result = await fetch(state.url + 'users', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(async res => {
          console.log('here')
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .catch(err => { alert(err); console.error(err); return [] })
      console.log(result)
      commit('toggleLoad', 'getUsers')
      return result
    },
    async createUser({ state, commit }, login) {
      commit('toggleLoad', 'createUser')
      let res = await fetch(state.url + 'user', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ login })
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.text()
        })
        .catch(err => { alert(err); console.error(err); return false })
      commit('toggleLoad', 'createUser')
      return res
    },
    async deleteUser({ state, commit }, login) {
      commit('toggleLoad', 'delUser')
      let res = await fetch(state.url + 'user', {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ login })
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return true
        })
        .catch(err => { alert(err); console.error(err); return false })
      commit('toggleLoad', 'delUser')
      return res
    },
    reset({ state, commit }) {
      if (state.instream)
        return alert('Нельзя это сделать во время стриминга сообщения')

      commit('setRole', false)
    },
    scroll() {
      window.scrollTo({ top: document.querySelector('.main__content').offsetHeight - window.innerHeight })
    },
    updateContext({ state, commit }) {
      if (state.instream)
        return alert('Нельзя это сделать во время стриминга сообщения')

      let current = false
      for (let i = 0; i < state.messages.length; i++) {
        let msg = state.messages[i]
        if (msg.role === "user")
          current = msg.inContext
        else
          msg.inContext = current
      }
      commit('setMessages', state.messages)
    },
    async checkAuth({ state, commit }) {
      if (state.instream)
        return alert('Нельзя это сделать во время стриминга сообщения')

      commit('toggleLoad', 'checkAuth')
      await fetch(state.url + 'auth', { credentials: 'include', })
        .then(res => {
          if (!res.ok) return commit('setRole', false)
          return res.text()
        }).then(role => {
          commit('setRole', role || false)
        }).catch(err => {
          console.error(err)
          commit('setRole', false)
        })
      commit('toggleLoad', 'checkAuth')
    },
    async updateAllUsers({ state, commit }) {
      if (state.instream)
        return alert('Нельзя это сделать во время стриминга сообщения')

      commit('toggleLoad', 'updateAllUsers')
      await fetch(state.url + 'users', { credentials: 'include', })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => {
          commit('setAllUsers', list)
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateAllUsers')
    },
    async createDialog({ state, commit, dispatch }, name = '') {
      if (state.instream)
        return alert('Нельзя это сделать во время стриминга сообщения')

      commit('toggleLoad', 'createDialog')
      await fetch(state.url + 'newdialog', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, model: state.model })
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.text()
        })
        .then(async id => {
          await dispatch('getDialogs')
          await dispatch('getDialog', id.replace(/^"/, '').replace(/"$/, ''))
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'createDialog')
    },
    async getDialogs({ state, commit }) {
      commit('toggleLoad', 'getDialogs')
      await fetch(state.url + 'dialogs' + `?model=${encodeURIComponent(state.model)}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(ds => {
          commit('setDialogs', ds)
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'getDialogs')
    },
    async renameDialog({ state, commit, dispatch }, { id, name }) {
      if (state.instream)
        return alert('Нельзя это сделать во время стриминга сообщения')
      if (!id)
        return alert('Диалог не указан')
      commit('toggleLoad', 'rename')
      await fetch(state.url + 'newdialog', {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ model: state.model, id, name })
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          dispatch('getDialogs')
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'rename')
    },
    async getDialog({ state, commit, dispatch }, id) {
      if (state.instream)
        return alert('Нельзя это сделать во время стриминга сообщения')
      if (!id)
        return dispatch('clearDialog')
      console.log(id)
      commit('toggleLoad', 'get')
      await fetch(state.url + 'dialog' + `?model=${encodeURIComponent(state.model)}&id=${encodeURIComponent(id)}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(dialog => {
          for (let m of dialog.messages)
            m.inContext = false
          commit('setMessages', dialog.messages)
          commit('setDialogId', id)
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'get')
    },
    async setMode({ state, commit, dispatch }, mode) {
      if (state.instream)
        return alert('Нельзя сменить режим во время стриминга сообщения')

      let model
      if (mode == 'text')
        model = 'GPT-4-mini'
      else
        model = 'Dalle-2'
      commit('setMode', mode)
      dispatch('setModel', model)
    },
    async setModel({ state, commit, dispatch }, model) {
      if (state.instream)
        return alert('Нельзя сменить модель во время стриминга сообщения')
      commit('setModel', model)
      await dispatch('getDialogs')
      await dispatch('getDialog')
    },
    async sendMessage({ state, commit, dispatch }, message) {
      if (state.instream) return alert('Нельзя создать второй запрос во время стриминга сообщения')
      if (!message || !message.trim())
        return alert('Пустое сообщение')
      if (!state.current_id)
        await dispatch('createDialog', message.substr(0, 50))
      if (!state.current_id)
        return
      const m = { role: 'user', content: message, inContext: false }
      let newMessages = [...state.messages]
      if (!state.contextEnabled) {
        let todelete = []
        for (let i = 0; i < newMessages.length; i++) {
          let msg = newMessages[i]
          if (msg.role !== "user")
            continue
          if (msg.inContext == false) {
            todelete.push(i)
            for (let j = i + 1; j < newMessages.length; j++) {
              if (newMessages[j].role !== "user")
                todelete.push(j)
              else
                break
            }
          }
        }
        newMessages = newMessages.filter((el, i) => !todelete.includes(i))
      }
      newMessages.push(m)
      let load = true
      commit('toggleLoad', 'stream')
      commit('setStream', true)
      var answer, added = false
      let xhr = new XMLHttpRequest()
      xhr.open("POST", state.url + 'dialog');
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.withCredentials = true
      xhr.onprogress = function () {
        if (load)
          commit('toggleLoad', 'stream')
        load = false
        let text = xhr.responseText
        let error = text.match(/\n\|\|\|Ошибка:/)
        let start = text.match('\n|||start|||\n')
        if (start) {
          if (!added) {
            commit('addMessage', m)
            added = true
          }
          text = text.replace('\n|||start|||\n', '')
        }

        if (!error) {
          let cost = '?'
          if (text.match(/\n\|\|\|Цена: .*/)) {
            cost = text.match(/\n\|\|\|Цена: .*/)[0]
            text = text.replace(cost, '')
            cost = cost.match(/: .*/)[0].replace(': ', '')
          }

          if (!answer) {
            if (text) {
              answer = { role: 'assistant', content: text, cost }
              commit('addMessage', answer)
            }
          } else {
            commit('setLastMessage', { ...answer, content: text, cost })
            dispatch('scroll')
          }
        }
        else {
          let err = text.match(/\n\|\|\|Ошибка:.*/)[0].replace(/\|/g, '')
          alert(err); console.error(err)
        }
      };
      xhr.onload = () => {
        if (load)
          commit('toggleLoad', 'stream')
        load = false
        console.log("DONE: ", xhr.status)
        commit('setStream', false)
      }
      xhr.send(JSON.stringify({ id: state.current_id, messages: newMessages, model: state.model }));

    },
    async sendImageMessage({ state, commit, dispatch }, message) {
      if (state.instream) return alert('Нельзя создать второй запрос во время стриминга сообщения')
      if (!message || !message.trim())
        return alert('Пустое сообщение')
      if (!state.current_id)
        await dispatch('createDialog', message.substr(0, 50))
      if (!state.current_id)
        return
      const m = { role: 'user', content: message, inContext: false }
      let newMessages = [m]
      commit('toggleLoad', 'image')
      await fetch(state.url + 'dialogImage', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: state.current_id, messages: newMessages, model: state.model })
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
        })
        .then(async () => await dispatch('getDialog', state.current_id))
        .catch(err => { alert(err); console.error(err); return false })
      commit('toggleLoad', 'image')
    },
    clearDialog({ commit }) {
      commit('setMessages', [])
      commit('setDialogId', null)
    },
    async deleteDialog({ state, commit, dispatch }, id) {
      if (state.instream)
        return alert('Нельзя это сделать во время стриминга сообщения')

      commit('toggleLoad', 'delete')
      await fetch(state.url + 'dialog', {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id, model: state.model })
      })
        .then(async res => {
          dispatch('clearDialog')
          dispatch('getDialogs')
          if (!res.ok) throw (await res.text())
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'delete')
    },
    async abort({ state, commit }) {
      commit('toggleLoad', 'abort')
      await fetch(state.url + 'dialog', {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({})
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          commit('setStream', false)
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'abort')
    },
  },
  getters: {
    loading(state) {
      return Object.keys(state.loads).length > 0
    },
    messagesLength(state) {
      return state.messages.length
    },
    g4(state) {
      return (state.model == 'GPT-4') || (state.model == 'Dalle-3')
    },
  }
})
