<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import Btn from '../buttons/Btn.vue';

//eslint-disable-next-line
const text = defineModel('text', { default: '' })
//eslint-disable-next-line
const emit = defineEmits(['sendPrompt'])
const theme = computed(() => text?.value?.match(/<\s*h[12][^>]*>(.*?)<\/\s*h[12]\s*>/)?.[1]?.trim() || 'Без заголовка')

const isValid = ref(false)
const updateVisible = ref(false)
const errors = ref([])
const textmode = ref('html')
const prompt = ref('')
const last = ref('')
const selection = ref('')
const selectionStart = ref(0)

const lastValidated = ref('')
const working = ref(false)

const currenthtml = computed(() => selection.value || text.value)
const initprompt = computed(() =>
  'Вот HTML код части статьи:\n' + currenthtml.value || ''
)
const initpromptReadable = computed(() => initprompt?.value.replace(currenthtml.value, selection.value || '<strong>*ТЕКУЩИЙ HTML*</strong>').trim())


function setSelection() {
  selection.value = window.getSelection().toString()
  selectionStart.value = document.activeElement.selectionStart
}

function clearSelection() {
  selection.value = ''
  selectionStart.value = 0
}

watch(() => textmode.value, (v) => {
  if (v != 'html')
    clearSelection()
})


//eslint-disable-next-line
onMounted(() => {
  validate()
})

watch(() => text.value, async (v) => {
  if (working.value == 'prompt') {
    clearSelection()
    if (v != lastValidated.value)
      await validate()
    working.value = false
  }
})

async function update() {
  let p = prompt.value.trim()
  if (!p)
    return alert('Укажите промпт')
  working.value = 'prompt'
  let fullprompt = `${initprompt.value}\n\n${p}`
  last.value = text.value
  let sel = selection.value || text.value
  emit('sendPrompt', { prompt: fullprompt, selection: sel, selectionStart: selectionStart.value })
}

async function validate() {
  try {
    let v = text.value?.trim()
    if (!v)
      return console.error('Нечего проверять')
    working.value = true
    const response = await fetch('https://validator.w3.org/nu/?out=json', {
      method: 'POST',
      headers: {
        'Content-Type': 'text/html; charset=utf-8'
      },
      body: `<!DOCTYPE html><html><head><title>123</title></head>${v}</html>`
    });
    const result = await response.json();
    errors.value = result.messages.filter(msg => msg.type === 'error').map(msg => ({
      lastLine: msg.lastLine,
      extract: msg.extract,
      message: msg.message,
    }));
    lastValidated.value = v
    isValid.value = result.messages.every(msg => msg.type !== 'error');
  } catch {
    isValid.value = false;
  }
  working.value = false
}

function goBack() {
  text.value = last.value
  last.value = ''
  setTimeout(validate, 10)
}

</script>
<template>
  <form @submit.prevent class="textviewer" :disabled="working">
    <h2 class="textviewer__title" v-if="!(textmode == 'text' && isValid)">{{ theme }}:</h2>
    <div class="textviewer__textmode">
      <label>
        <input type="radio" name="textmode" v-model="textmode" value="html">
        Смотреть Html
      </label>
      <label>
        <input type="radio" name="textmode" v-model="textmode" value="text">
        Предпросмотр
      </label>
    </div>
    <p v-if="!isValid" class="bg-pink">В html обнаружены ошибки, осторожно! (Проверить можно кликнув "Предпросмотр")</p>
    <textarea @click="clearSelection" class="toolsprovider__input toolsprovider__input_area textreviewer__area"
      v-if="textmode == 'html'" v-model="text" @select="setSelection" @blur="check"></textarea>
    <div class="textviewer__preview" v-if="textmode == 'text'">
      <div v-if="working == 'prompt'">Ожидание результата...</div>
      <div v-else-if="isValid" v-html="text"></div>
      <div v-else>
        <pre>{{ JSON.stringify(errors, null, 2) }}</pre>
      </div>
    </div>
    <div class="textviewer__actions" style="margin-top: 10px;" @mousedown.prevent>
      <Btn v-if="lastValidated != text" class="wform" color="green" @click="validate" :small="true">Сделать повторную
        валидацию</Btn>
      <Btn color="blue" @click="updateVisible = true" :small="true" v-if="!updateVisible">Изменить с использованием ИИ
      </Btn>
      <div v-if="updateVisible" class="w100 textviewer__actions" @mousedown.prevent>
        <p class="toolsprovider__text">Переделать текст через ИИ использовав промпт:</p>
        <i style="margin-bottom: 10px; display: block; white-space: pre-wrap;">
          <span v-if="!selection" v-html="initpromptReadable" />
          <span v-else style="font-size: 14px;">{{ initpromptReadable }}</span>
        </i>
        <textarea @mousedown.stop class="toolsprovider__input w100 toolsprovider__input_area" v-model="prompt"
          placeholder="Распиши его подробнее" style="margin-bottom: 10px; height: 65px" />
        <div style="display: flex; gap: 10px; align-items: center;">
          <Btn :small="true"  @mousedown.prevent.stop :disabled="!prompt.trim() || (working == 'prompt')"
            class="wform toolsprovider__btn" color="blue" @click.prevent.stop="update">
            <template v-if="(working == 'prompt')">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="20" stroke-width="8" stroke="#007bff"
                  stroke-dasharray="31.41592653589793 31.41592653589793" fill="none" stroke-linecap="round">
                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s"
                    keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                </circle>
              </svg>
              <span>Переделываю...</span>
            </template>
            <span v-else>Переделать</span>
          </Btn>
          <Btn color="dark" :small="true" @click="updateVisible = false">Скрыть это меню</Btn>
          <Btn color="red" v-if="last && (working != 'prompt')" @click="goBack" :small="true">Отмена последней переделки</Btn>
        </div>
      </div>
    </div>
  </form>
</template>
<style lang="sass">
.textviewer
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px
  padding: 15px
  margin: 20px 0
  
  &__actions
    display: flex
    flex-direction: column
    gap: 10px
    align-items: flex-start

  &__title
    font-weight: 700
    font-size: 16px
    margin: 0 0 5px
  &__textmode
    margin-bottom: 10px
  & &__preview
    *
      all: revert
    font-size: 14px
    h1,h2,h3,h4,h5,h6,p,ul,li
      margin: 0
    h1
      margin-bottom: 20px
      font-size: 30px
    h2
      font-size: 20px
      margin-bottom: 10px 
    h3,h4,h5,h6
      margin-bottom: 5px
    ul,p,ol
      margin-bottom: 10px
    li+li
      margin-top: 5px
</style>