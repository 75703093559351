<template>
  <Btn @click.stop="logout" color="dark">
    <svg style="width: 16px; margin: 0 -3px" stroke-width="20" width="16px" version="1.1"
      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 384.971 384.971" xml:space="preserve">
      <g>
        <path stroke-width="35"
          d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03 C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03 C192.485,366.299,187.095,360.91,180.455,360.91z" />
        <path
          d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279 c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179 c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z" />
      </g>
    </svg>
  </Btn>
</template>

<script>
import { mapState } from 'vuex';
import Btn from './Btn.vue';
export default {
  components: {
    Btn
  },
  methods: {
    async logout() {
      this.$store.commit('toggleLoad', 'logoutBtn')
      await fetch(this.url + 'logout', { credentials: 'include', })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          this.$store.dispatch('reset')
          this.$store.dispatch('checkAuth')
        }).catch(err => { console.error(err); alert(err) })
      this.$store.commit('toggleLoad', 'logoutBtn')
    }
  },
  computed: {
    ...mapState(['url'])
  }
}
</script>

<style lang="sass" scoped>

</style>