<script setup>
import Icon from './Icon.vue';

//eslint-disable-next-line
const modelValue = defineModel()
const options = [
  ['Генерация текстов', 'textgen'],
  ['Определение категорий', 'catdef'],
  ['Привязка отзывов', 'reviewslink']
]
//eslint-disable-next-line
const { look } = defineProps(['look'])

</script>

<template>
  <div class="toolschooser" :class="`toolschooser_${look}`">
    <button class="toolschooser__option" :class="{ 'active': modelValue === option[1] }" v-for="option in options"
      :key="option[0]" @click="modelValue = option[1]">
      <Icon :name="option[1]" />
      <span :data-content=option[0]>{{ option[0] }}</span>
    </button>
  </div>
</template>

<style lang="sass">
.toolschooser
  --bordercolor: rgba(255, 255, 255, 0.2)
  --borderactive: rgba(255, 255, 255, 0.8)
  border: 1px solid var(--bordercolor)
  border-left: none
  border-right: none
  &__option
    width: 100%
    height: 55px
    display: flex
    align-items: center
    padding-left: 25px
    gap: 13px
    background: none
    color: white
    border: 1px solid var(--bordercolor)
    border-left: none
    border-right: none
    margin: -1px 0
    transition: border-color .2s ease-out, background .2s ease-out
    cursor: pointer
    span
      display: inline-block
      position: relative
    &:hover
      background: rgba(255, 255, 255, 0.05)
    &.active
      background: rgba(255, 255, 255, 0.05)
      border-color: white
      svg
        opacity: 1
      span::after
        content: attr(data-content)
        position: absolute
        transform: translateX(0.5px)
        left: 0
    svg
      transition: opacity .2s ease-out
      fill: currentColor
      opacity: 0.5
      width: 25px 
    
  &_2
    display: flex
    flex-wrap: wrap
    gap: 20px
    flex-direction: row
    align-items: stretch
  &_2 &__option
    height: auto
    width: fit-content
    color: colors.$dark
    justify-content: center
    align-items: center
    display: flex
    padding: 10px 20px
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px
    font-size: 14px
    &:hover
      background: white
      color: black
      span::after
        content: attr(data-content)
        position: absolute
        transform: translateX(-0.5px)
        left: 0


</style>