<template>
  <div class="tools">
    <div class="tools__sidebar">
      <div class="tools__sidebar-title">Инструменты:</div>
      <ToolsChoser v-model="mode" />
    </div>
    <div class="tools__content">
      <TopPanel :modes="false" />
      <ToolsProvider />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TopPanel from '../components/TopPanel.vue';
import ToolsChoser from '@/components/ToolsChoser.vue';
import ToolsProvider from '@/components/ToolsProvider.vue';
import { provide, ref } from 'vue';

export default {
  name: 'tools',
  components: { TopPanel, ToolsChoser, ToolsProvider },
  setup(){
    const mode = ref('')
    provide('mode', mode)
    return { mode }
  },
  computed: {
    ...mapState(['Role'])
  },
  created(){
    if (this.Role != 2) {
      if (this.Role)
        this.$router.push('/app')
      else
        this.$router.push('/login')
    }
  }
}
</script>
<style lang="sass">

.tools
  color: colors.$dark
  --sidebar_width: 300px
  input, textarea, select
    outline: none
    &:focus-visible
      border-color: black

  position: relative
  min-height: 100vh
  //background: url('~@/assets/bg.webp')
  background: white
  background-repeat: repeat
  display: flex
  &::before
    content: ''
    position: absolute
    left:  0
    top: 0
    width: 100%
    height: 100%
    background: rgba(255,255,255,.5)   
    backdrop-filter: blur(2px)
    z-index: -1
  &__sidebar
    display: flex
    flex-direction: column
    padding: 20px 0 0
    background: colors.$dark
    align-self: stretch
    overflow-y: auto
    scrollbar-width: thin
    flex-shrink: 0
    flex-basis: var(--sidebar_width)
    &-title
      letter-spacing: .1em
      color: white
      font-weight: 300
      font-size: 16px
      margin-bottom: 13px
      text-align: center
    &::-webkit-scrollbar 
      width: 6px
    &::-webkit-scrollbar-track 
      background: white
    &::-webkit-scrollbar-thumb 
      background: rgba(0,0,0,0.2) 
  &__content
    width: 100%
    padding: 0 30px
    display: flex
    flex-grow: 1
    flex-direction: column
    align-self: flex-start
    align-items: flex-start
    min-height: 100vh

    *
      position: relative
    
  &__list
    min-width: 200px
    max-width: 300px
    margin-right: 30px
  &__viewer
    flex-grow: 1
  &__data
    align-self: stretch
    margin-left: 20px
    flex-grow: 1
</style>