<script setup>
/*eslint-disable*/
import useApi from '@/composables/useApi';
import useFiles from '@/composables/useFiles';
import useCatlist from '@/composables/useCatlist';
import Btn from '../buttons/Btn.vue';
import { computed, onMounted, ref, useTemplateRef } from 'vue';
import useGrouping from '@/composables/useGrouping';

const { askAi, cost, costReadable, isWorking } = useApi()
const { readFile, downloadTextAsFile, generateExcel } = useFiles()
const { getCatlists, setCatlist, deleteCatlist, isWorking: catlistLoading } = useCatlist()
const { grouping, isGroupCorrect, groupingInput } = useGrouping()

const catsThemesInput = useTemplateRef('catsThemesInput')

let id = 0
const gptVersion = ref('gpt-4o-mini')
const themes = ref([])
const curName = ref('')
const statfound = ref(0)
const statsearched = ref(0)
const allcats = ref([])
const allCatsLast = ref([])
const curCat = computed(() => allcats.value.find(el => el.name == curName.value))
const curCatLast = computed(() => allCatsLast.value.find(el => el.name == curName.value))
const cattext = computed({
  get: () => {
    return curCat.value?.cats || ''
  },
  set: (newValue) => {
    curCat.value.cats = newValue
  }
});

const results = ref([])
const busy = computed(() => isWorking.value || catlistLoading.value)
async function updateCats() {
  allcats.value = await getCatlists();
  let v = []
  for (let cat of allcats.value)
    v.push(JSON.parse(JSON.stringify(cat)))
  allCatsLast.value = v
}


onMounted(updateCats)
const catNames = computed(() => allcats.value.map(el => el.name))
async function add() {
  const name = prompt('Введите название нового списка категорий').trim()
  if (catNames.value.includes(name))
    alert('Список с таким названием уже существует')
  else {
    await setCatlist(name, '')
    await updateCats()
  }
}
async function del() {
  let ok = await deleteCatlist(curName.value);
  if (ok)
    curName.value = ''
  await updateCats()
}

async function save() {
  await setCatlist(curName.value, cattext.value)
  await updateCats()
}

const currentTextArray = computed(() => cattext.value.split('\n').map(el => el.trim()).filter(el => el))
const NoDoublicates = computed(() => new Set(currentTextArray.value).size === currentTextArray.value.length)
const DoublicateList = computed(() => {
  let store = {}, res = []
  for (let item of currentTextArray.value) {
    let lc = item.toLowerCase()
    store[lc] = (store[lc] || 0) + 1
    if (store[lc] > 1)
      res.push(lc)
  }
  return res
})

const changed = computed(() => cattext.value != curCatLast.value?.cats)

async function setFileContent(e) {
  const content = await readFile(e)

  let res = []
  if (e.target === catsThemesInput.value) {
    themes.value = []
    let v = content.split('\n').map(el => el.trim()).filter(el => el)
    for (let theme of v)
      res.push(theme)
    if (Array.isArray(res))
      themes.value = res
    else {
      alert('Неверный тип массива')
      console.error('Неверный тип массива')
      console.error(res)
    }
  }
}


async function gen() {
  if (!currentTextArray.value.length) {
    return alert('Пустой список категорий')
  }
  if (!NoDoublicates.value) {
    return alert('Сначала уберите дубликаты из списка категорий: ' + DoublicateList.value.join(', '))
  }
  isWorking.value = true
  cost.value = statsearched.value = statfound.value = 0
  results.value = []
  let data = []
  let cur = 0

  while (cur < themes.value.length) {
    let { store, isERROR } = await processThemes(themes.value.slice(cur, cur + grouping.value))
    for (let el of store) {
      statsearched.value++
      if (el.result)
        statfound.value++
    }

    cur += store.length
    data.push(...store)
    if (isERROR !== false) {
      alert(`Ошибка при обработке тем${grouping.value > 1 ? '' : 'ы'} ${isERROR}, дальшная работа прервана`)
      break
    }
  }

  for (let el of data) {
    let res = el.result
    if (res)
      results.value.push({ id: ++id, theme: el.theme, found: true, res: res })
    else if (res === 0)
      results.value.push({ id: ++id, theme: el.theme, found: false, res: 'Не определено' })
    else
      results.value.push({ id: ++id, theme: el.theme, found: false, res: 'ОШИБКА Некорректный ответ от ИИ' })
  }

  isWorking.value = false
  if (results.value.length > 0) {
    try {
      generateExcel('reviews_linker_results', 'Категории', results.value.map(el => [el.theme, el.res]))
    } catch (err) {
      downloadTextAsFile(`reviews_linker_results_${new Date().toLocaleDateString('ru-RU')}_.txt`, results.value.map(el => el.theme + ' → ' + el.res).join('\n'))
    }
  }
}

async function processThemes(themes) {
  let store = [], isERROR = false

  if (themes.length == 1) {
    const theme = themes[0]
    const prompt = `Вот список категорий: ${currentTextArray.value.join('\n')}\n\nНазови, к какой категории из этого списка относится статья с данным заголовком: \n${theme}\n\n. Твой ответ должен содержать исключительно одну категорию из списка. Нежелательно, но если совсем ничего не подходит, можешь ответить цифрой 0.`;
    let res = await askAi(prompt, gptVersion.value, { tries: 3, addcost: true })
    if (typeof (res?.answer) == 'string') {
      if (res.answer?.trim() == '0')
        store.push({ theme, result: 0 })
      else
        store.push({ theme, result: currentTextArray.value.find(el => el.toLowerCase().trim() == res.answer.toLowerCase().trim()) || null })
    } else
      isERROR = true
  }
  else {
    let catlist = currentTextArray.value.map((el, i) => `${i + 1}. ${el}`).join('\n')
    const themelist = themes.map((el, i) => `${i + 1}. ${el}`).join('\n')
    const prompt = `Вот список категорий: ${catlist}\n\nНазови, к какой категории из этого списка относится статьи со следующими заголовками: \n${themelist}\n\n. Ответь в виде N строк, каждая строка N содержит номер из списка категорий подходящий для статьи N. Если подходящего номера для статьи N нет, в соответствующей строке поставь 0.`;
    let res = await askAi(prompt, gptVersion.value, { tries: 3, addcost: true })
    let resAr = res?.answer.trim().split('\n').map(el => el.trim().toLowerCase())
    if ((typeof (res?.answer) != 'string') || (!Array.isArray(resAr)) || (resAr.length != themes.length)) {
      isERROR = themes[0] + ' - ' + themes[themes.length - 1]
    } else {
      for (let i in resAr) {
        let theme = themes[i]
        let r = { theme }

        let ans = resAr[i]
        let nodotans = ans
        if (nodotans.match(/\.$/))
          nodotans = nodotans.replace(/\.$/, '')
        let nnans = ans.replace(/^\s*[#№]?\s*\d+\s*\.?\s*/, ''), nnnodotans = nodotans.replace(/^\s*[#№]?\s*\d+\s*\.?\s*/, '')
        
        if ([nodotans, nnnodotans].includes('0'))
          r.result =  0
        else {
          let v = -1
          for (let el of [nnans, nnnodotans, ans, nodotans]) {
            
            if (!isNaN(parseInt(el))) {
              v = parseInt(el)
              v--
              break
            }
          }        
          
          r.result = currentTextArray.value[v] || console.log(ans) || null
        }

        store.push(r)
      }
    }
  }
  return { store, isERROR }
}

</script>
<template>
  <div class="toolsprovider__big catdefiner">
    <form @submit.prevent="gen" class="toolsprovider__form">
      <h1 class="toolsprovider__title">Определить категории статей</h1>
      <label class="toolsprovider__label finput-label">
        <span>Загрузите txt файл с темами (каждая тема должна быть с новой строки)</span>
        <input name="themes" ref="catsThemesInput" class="finput" type="file" @change="setFileContent" />
      </label>
      <label class="toolsprovider__label">
        <span>Группировать:</span>
        <input type="number" ref="groupingInput" class="toolsprovider__input" v-model="grouping" />
      </label>
      <select class="toolsprovider__input toolsprovider__input_select" v-model="gptVersion">
        <option value="gpt-4o-mini">GPT-4o mini</option>
        <option value="gpt-4o">GPT-4o</option>
      </select>
      <p v-if="statsearched" class="toolsprovider__text">Найдено {{ statfound }} из {{ statsearched }} ({{ (statfound /
        statsearched * 100).toFixed(2) }}%)</p>
      <p v-if="cost" class="toolsprovider__text">Примерная стоимость: {{ costReadable }}</p>
      <Btn color="dark" :disabled="busy || changed || !isGroupCorrect" class="toolsprovider__btn">
        <template v-if="isWorking">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="20" stroke-width="8" stroke="#007bff"
              stroke-dasharray="31.41592653589793 31.41592653589793" fill="none" stroke-linecap="round">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s"
                keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
            </circle>
          </svg>
          <span>Определяю...</span>
        </template>
        <span v-else>Определить</span>
      </Btn>
    </form>
    <div class="toolsprovider__form">
      <h1 class="toolsprovider__title">Указать список категорий</h1>
      <label class="toolsprovider__label2">
        <span>Название списка:</span>
        <div class="toolsprovider__label2-content">
          <select class="toolsprovider__input toolsprovider__input_select" v-model="curName">
            <option value="" />
            <option v-for="name in catNames" :key="name" :value="name">{{ name }}</option>
          </select>
          <Btn color="green" class="catdefiner__add" title="Добавить" @click="add">+</Btn>
          <Btn color="red" v-if="curName" class="catdefiner__add" title="Удалить" @click="del">–</Btn>
        </div>
      </label>
      <textarea v-if="curName" class="toolsprovider__input toolsprovider__input_area" v-model="cattext" />
      <p class="toolsprovider__text toolsprovider__text_sm red" v-if="!NoDoublicates">Обнаружены дубликаты:<br>
        <span style="white-space: pre">{{ DoublicateList.join('\n') }}</span>
      </p>
      <Btn class="toolsprovider__btn" v-if="curName" color="dark" @click="save"
        :disabled="busy || !NoDoublicates || !changed">
        <template v-if="catlistLoading">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="20" stroke-width="8" stroke="#007bff"
              stroke-dasharray="31.41592653589793 31.41592653589793" fill="none" stroke-linecap="round">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s"
                keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
            </circle>
          </svg>
          <span>Работаю...</span>
        </template>
        <span v-else>Сохранить</span>
      </Btn>
    </div>
  </div>
  <table v-if="results.length" class="toolsprovider__table">
    <thead>
      <tr>
        <th>Тема</th>
        <th>Категория</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="{ theme, res, id, found } in results" :key="id" :class="{ 'toolsprovider__table-red': !found }">
        <td>{{ theme }}</td>
        <td>{{ res }}</td>
      </tr>
    </tbody>
  </table>
</template>
<style lang="sass">
.catdefiner
  &__add
    font-weight: 700
    font-size: 18px
</style>