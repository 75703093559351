<template>
  <LoadScreen />
  <Teleport to="body">
    <svg width="0" height="0" style="position: absolute;opacity: 0;pointer-events: none;">
      <symbol viewBox="0 0 512 512" id="reviewslink">
        <path
          d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32z">
        </path>
      </symbol>
      <symbol viewBox="0 0 512 512" id="catdef">
        <path
          d="M80 368H16a16 16 0 0 0 -16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0 -16-16zm0-320H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0 -16-16zm0 160H16a16 16 0 0 0 -16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0 -16-16zm416 176H176a16 16 0 0 0 -16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0 -16-16zm0-320H176a16 16 0 0 0 -16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16zm0 160H176a16 16 0 0 0 -16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0 -16-16z">
        </path>
      </symbol>
      <symbol viewBox="0 0 576 512" id="textgen">
        <path
          d="M552 64H88c-13.3 0-24 10.7-24 24v8H24c-13.3 0-24 10.7-24 24v272c0 30.9 25.1 56 56 56h472c26.5 0 48-21.5 48-48V88c0-13.3-10.7-24-24-24zM56 400a8 8 0 0 1 -8-8V144h16v248a8 8 0 0 1 -8 8zm236-16H140c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h152c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12zm208 0H348c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h152c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12zm-208-96H140c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h152c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12zm208 0H348c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h152c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12zm0-96H140c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z">
        </path>
      </symbol>
      <symbol viewBox="0 0 384 512" id="close">
        <path
          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z">
        </path>
      </symbol>
    </svg>
  </Teleport>
  <router-view v-if="Role != null" v-slot="{ Component }">
    <transition name="fade" mode="ease-in" v-if="!this.delayedRole">
      <component :is="Component" />
    </transition>
    <component v-else :is="Component" />
  </router-view>
</template>

<script>
import { provide, ref } from 'vue';
import LoadScreen from './views/LoadScreen.vue'
import { mapState } from 'vuex'

export default {
  components: { LoadScreen },
  setup() {
    const viewBoxes = ref({
      'textgen': '0 0 576 512',
      'catdef': '0 0 512 512',
      'reviewslink': '0 0 512 512',
      'close': '0 0  384 512'
    })
    provide('viewBoxes', viewBoxes)
    return { viewBoxes }
  },
  data: () => ({
    inited: false,
    delayedRole: 0,
    timer: undefined
  }),
  async created() {
    let base = window.location.origin
    if (process.env.NODE_ENV == 'development') {
      console.log("DEV_MODE")
      base = base.replace('8080', '3205')
    }
    this.$store.commit('setUrl', base + '/')
  },
  mounted() {
    this.$store.dispatch('checkAuth')
    document.head.querySelector('title').innerHTML = 'Chatgpt'
    setTimeout(() => {
      if (!this.inited)
        this.init()
    }, 100)
  },
  methods: {
    init() {
      this.inited = true
      if (this.Role) {
        if ((this.Role == 1) || ((this.Role == 2) && this.$router.currentRoute.value.path != '/tools'))
          this.$router.push('/app')
        else
          this.$router.push('/tools')
      }
      else
        this.$router.push('/login')
    }
  },
  computed: {
    ...mapState(['Role'])
  },
  watch: {
    Role(v) {
      this.init()
      clearTimeout(this.timer)
      if (!v || (v == 1 && (this.$router.currentRoute.value.path != '/app')))
        this.delayedRole = v
      else
        this.timer = setTimeout(() => this.delayedRole = v, 400)
    }
  }
}
</script>

<style lang="sass">

.w100
  width: 100%
.red
  color: colors.$red
.finput
  &-label.finput-label
    cursor: pointer
    display: flex
    flex-direction: column
    gap: 5px
    align-items: flex-start
    text-align: left
  &::file-selector-button 
    border-width: 0
    padding: 10px 10px
    border-right: 1px solid rgba(0,0,0,0.1)
    cursor: pointer
    border-radius: 0    
    background: rgba(0,0,0,0.03)
  background: none
  cursor: pointer
  width: 100%
  background-color: #fff
  border: 1px solid #e9ecef
  appearance: none
  border-radius: 2px
  box-shadow: none
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out

body
  background: white !important
  scrollbar-width: thin
  color: colors.$dark
  &::-webkit-scrollbar 
    width: 6px
  &::-webkit-scrollbar-track 
    background: white
  &::-webkit-scrollbar-thumb 
    background: rgba(0,0,0,0.2) 
  *, *::after, *::before
    box-sizing: border-box
    font-family: 'Open Sans', sans-serif

.fade-enter-active, .fade-leave-active
  transition: opacity .4s ease
.fade-enter-from, .fade-leave-to
  opacity: 0
  user-select: none
body
  margin: 0
  padding: 0
  ul, li
    margin: 0
    padding: 0
    list-style-type: none
</style>
