import { computed, ref, useTemplateRef } from "vue"

export default function useGrouping() {
  const grouping = ref(1)
  const groupingInput = useTemplateRef('groupingInput')
  
  const isGroupCorrect = computed(() => {
    return grouping.value > 0 && parseInt(grouping.value) == grouping.value
  })

  return { grouping, isGroupCorrect, groupingInput }
}