<script setup>
import useApi from '@/composables/useApi';
import useFiles from '@/composables/useFiles';
import Btn from '../buttons/Btn.vue';
import TextViewer from './TextViewer.vue';
import { computed, ref } from 'vue';
import TextGenPrompt from './TextGenPrompt.vue';
const { askAi, cost, costReadable, isWorking } = useApi()
const { downloadTextAsFile } = useFiles()

let id = 0
const gptVersion = ref('gpt-4o-mini')
const promptsGigaArray = ref([{ requests: [], id: id++, mode: 'one', error: '' }])

const results = ref([])

function addReq() {
  promptsGigaArray.value.push({ requests: [], id: id++, mode: 'one', error: '' })
}

function delReq(id) {
  const really = confirm('Действительно удалить данный промпт?')
  if (!really) return
  promptsGigaArray.value = promptsGigaArray.value.filter(el => el.id != id)
}

async function gen() {
  cost.value = 0
  results.value = []
  let err = false
  if (!promptsGigaArray.value.length)
    return alert('Не указано ни одного промта')
  for (let i in promptsGigaArray.value) {
    let { error } = promptsGigaArray.value[i]
    if (error)
      return alert(`Ошибка в блоке ${i}: ${error}`)
  }

  isWorking.value = true
  try {
    for (let { requests } of promptsGigaArray.value) {
      for (let prompt of requests) {
        let res = await askAi(prompt, gptVersion.value, { tries: 3, timeout_seconds: 120, addcost: true })
        if (typeof (res.answer) != 'string') {
          err = prompt.substring(0, 50)
          if (prompt.length > 50) err += '...'
          break
        }
        let text = res.answer
        if (text.match(/```html/)) {
          try {
            const match = text.match(/```html\s([\s\S]*?)\s```/);
            if (match)
              text = match[1].trim()
          } catch (err) { console.error(err) }
        }
        if (text.match(/<body>/i)) {
          try {
            text = text.match(/<body>([\s\S]*?)<\/body>/)[1].trim()
          } catch (err) { console.error(err) }
        }
        results.value.push(...splitHTMLByHeaders(text))
      }
      if (err) break
    }
    isWorking.value = false
    if (err)
      return alert(`Ошибка при запросе ${err}, дальшая работа прервана`)
  } catch (gigaErr) {
    isWorking.value = false
    alert(`Ошибка ${gigaErr}, дальшая работа прервана`)
  }
}

const isError = computed(() => {
  for (let el of promptsGigaArray.value) {
    if (el.error) return true
  }
  return false
})

function download() {
  let v = results.value.map(el => el.text).join('\n')
  downloadTextAsFile(`textgen_${new Date().toLocaleDateString('ru-RU')}_.html`, v, { texttype: 'html' })
}

function splitHTMLByHeaders(html) {
  const regex = /(<h[12][^>]*>.*?<\/h[12]>)/gi
  const headers = html.split(regex).map(el => el.trim()).filter(Boolean)
  const chunks = []
  let currentChunk = ''

  headers.forEach((part) => {
    if (/<h[12][^>]*>/.test(part)) {
      if (currentChunk) chunks.push({ text: currentChunk, id: id++ })
      currentChunk = part.trim();
    } else {
      if (currentChunk)
        currentChunk += '\n'
      currentChunk += part.trim()
    }
  });

  if (currentChunk) chunks.push({ text: currentChunk, id: id++ })
  return chunks;
}

async function updateText(chunk, event) {
  const { prompt, selection, selectionStart } = event
  let res = await askAi(prompt, gptVersion.value, { tries: 3, timeout_seconds: 120, addcost: true })
  if (typeof (res.answer) != 'string') {
    alert(`Ошибка при обновлении текста`)
  } else {
    let text = res.answer
    if (text.match(/```html/)) {
      try {
        const match = text.match(/```html\s([\s\S]*?)\s```/);
        if (match)
          text = match[1].trim()
      } catch (err) {
        console.error(err)
      }
    }
    if (!selection) {
      chunk.text = text || chunk.text
    } else {
      let oldPart = chunk.text.substring(selectionStart, selectionStart + selection.length)
      let split = chunk.text.split(oldPart)
      chunk.text = split[0] + text + split[1]
    }
  }
}



</script>
<template>
  <form @submit.prevent="gen" class="toolsprovider__form" :disabled="isWorking">
    <h1 class="toolsprovider__title">Генерировать текст на заданную тему</h1>
    <TextGenPrompt v-for="par, index in promptsGigaArray" :key="par.id" v-model:mode="promptsGigaArray[index].mode"
      v-model:requests="promptsGigaArray[index].requests" @delete="() => delReq(par.id)"
      v-model:error="promptsGigaArray[index].error" />
    <Btn color="green" class="toolsprovider__btn w100" @click.stop.prevent="addReq" :small="true">Добавить запрос</Btn>
    <select class="toolsprovider__input toolsprovider__input_select" v-model="gptVersion">
      <option value="gpt-4o-mini">GPT-4o mini</option>
      <option value="gpt-4o">GPT-4o</option>
    </select>
    <p v-if="cost" class="toolsprovider__text">Примерная стоимость: {{ costReadable }}</p>
    <Btn color="dark" :disabled="isWorking || isError" class="toolsprovider__btn">
      <template v-if="isWorking">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="20" stroke-width="8" stroke="#007bff"
            stroke-dasharray="31.41592653589793 31.41592653589793" fill="none" stroke-linecap="round">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s"
              keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
          </circle>
        </svg>
        <span>Генерирую...</span>
      </template>
      <span v-else>Генерировать</span>
    </Btn>
  </form>
  <TextViewer v-for="result in results" :key="result.id" v-model:text="result.text"
    @sendPrompt="(e) => updateText(result, e)" />
  <div class="toolsprovider__stickdown" v-if="results.length">
    <p v-if="cost" class="toolsprovider__text">Примерная стоимость: {{ costReadable }}</p>
    <Btn :disabled="isWorking" v-if="results.length" color="green" class="toolsprovider__btn w100" @click="download">
      Скачать файл</Btn>
  </div>
</template>
<style></style>