import { computed, ref } from "vue"
import { useStore } from "vuex"

export default function useApi() {
  const store = useStore()
  const isWorking = ref(false)
  const cost = ref(0)
  const costReadable = computed(() => cost.value.toFixed(2) + ' ₽')
  async function askAi(message, model, options = {}) {
    const tries = options?.tries || 1
    const timeout = options?.timeout || 1000 
    for (let i = 1; i <= tries; i++) {
      let response = await fetch(store.state.url + 'text', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ model: model, message, history: options?.history, timeout_seconds: options.timeout_seconds || 40 })
      }).then(async res => {
        if (res.ok) {
          let json = await res.json()
          if (options?.addcost && (typeof (json?.cost) === 'number')) {
            cost.value += json.cost
          }
          return json
        }
        else
          throw (await res.text())
      }).catch(err => { alert(err); console.error(err); return false })
      if (response || (i == tries))
        return response
      else {
        await new Promise(resolve => setTimeout(resolve, timeout))
      }
    }
  }

  return { askAi, cost, costReadable, isWorking }
}