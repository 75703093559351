<script setup>
import { ref, watch, watchEffect } from 'vue';
import Icon from '../Icon.vue';

//eslint-disable-next-line
const requests = defineModel('requests', { default: [] })
//eslint-disable-next-line
const mode = defineModel('mode', { default: 'one' })
//eslint-disable-next-line
const error = defineModel('error', { default: '' })
const pureReq = ref('')
const themesRaw = ref('')
const themes = ref([])
const reqTemplate = ref('')
//eslint-disable-next-line
const emit = defineEmits(['delete'])

watch(() => themesRaw.value, (v) => {
  themes.value = v.split('\n').map(el => el.trim()).filter(el => el)
})

watchEffect(() => {
  if (mode.value == 'multiple') {
    let v = []
    error.value = ''
    let template = reqTemplate.value.trim()
    if (!template)
      error.value = 'Не указан шаблон запроса'
    else if (!template.match(/\[ТЕМА\]/))
      error.value = 'Шаблон запроса не содержит ключевого слова [ТЕМА]'
    if (!themes.value.length)
      error.value = 'Не указаны темы для запроса'
    for (let theme of themes.value) {
      v.push(template.replace(/\[ТЕМА\]/g, theme).trim())
    }
    requests.value = v
  }
  if (mode.value == 'one') {
    let v = pureReq.value.trim()
    error.value = ''
    if (!v)
      error.value = 'Не указан промпт'
    requests.value = [v].filter(el => el)
  }
})

</script>
<template>
  <form class="textprompt" :class="{ 'textprompt_errored': error }">
    <Icon name="close" class="textprompt__close" @click="$emit('delete')" />
    <label>
      <input type="radio" name="mode" v-model="mode" value="one">
      Один запрос
    </label>
    <label>
      <input type="radio" name="mode" v-model="mode" value="multiple">
      Несколько запросов
    </label>
    <template v-if="mode == 'one'">
      <p class="toolsprovider__text">Укажите промпт: </p>
      <textarea class="toolsprovider__input toolsprovider__input_area" v-model="pureReq"
        placeholder="Напиши вступительный HTML для статьи на тему (<h1>) Законы ньютона" />
    </template>
    <template v-if="mode == 'multiple'">
      <p class="toolsprovider__text">Укажите список тем: </p>
      <textarea class="toolsprovider__input toolsprovider__input_area" v-model="themesRaw"
        placeholder="Первый закон ньютон&#10;Второй закон ньютон&#10;Третий закон ньютона" />
      <p class="toolsprovider__text" style="margin-top: 10px;">Укажите промпт который будет выполнен для каждой темы:
      </p>
      <p class="toolsprovider__text toolsprovider__text_mini"><i>Используйте ключевое слово [ТЕМА] чтобы обратиться к
          текущей теме</i></p>
      <textarea class="toolsprovider__input toolsprovider__input_area"
        placeholder="Напиши HTML абзац для статьи, на тему (<h2>) [ТЕМА]" v-model="reqTemplate" />
      <p v-if="error" style="margin-top: 10px; font-size: 14px;" class="toolsprovider__text red">{{error}}</p>

    </template>
  </form>
</template>
<style lang="sass">
.textprompt
  padding: 10px
  background: rgba(0,0,0,.05)
  border: 1px solid color.adjust(colors.$realgreen, $alpha: -0.6)
  position: relative
  &_errored
    border: 1px solid color.adjust(colors.$red, $alpha: -0.6)
  & &__close
    position: absolute
    top: 10px
    right: 10px
    fill: colors.$red
    z-index: 1
    width: 15px
    cursor: pointer
</style>